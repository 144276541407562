<template>
    <div>
        <CRow>
            <CCol sm="4">
                <CImg name="1" align="center" rounded src="/img/restart_server.png" width="100" height="100" />
                <a href="javascript:void(0)" class="a-btn-text" v-on:click="restartServer">{{ $t('pages.system.restartServer') }}</a>

            </CCol>
            <CCol sm="4">
                <CImg name="2" align="center" rounded src="/img/clear_cache.png" width="100" height="100" />

                <a href="javascript:void(0)" class="a-btn-text" v-on:click="clearCache">{{ $t('pages.system.cleanCache') }}</a>
            </CCol>
        </CRow>
        <CRow>
            <Loading :active.sync="isRestartingServer"
                     :can-cancel="false"
                     :is-full-page="true" />

            <Loading :active.sync="isCleaningCache"
                     :can-cancel="false"
                     :is-full-page="true" />
        </CRow>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        name: 'configs',
        data() {
            return {
                isRestartingServer: false,
                isCleaningCache: false
            };
        },
        components: {
            Loading
        },
        methods: {
            async restartServer() {
                this.isRestartingServer = true;
                console.log("LOADING", this.$loading)
                var result = await this.$systemService.restartServer();
                this.isRestartingServer = false;
            },
            async clearCache() {
                this.isCleaningCache = true;
                var result = await this.$systemService.clearCache();
                this.isCleaningCache = false;
            }

        }
    }
</script>
<style scoped>
    .a-btn-text {
        padding-left:5px;
    }
</style>